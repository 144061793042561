import { Admin, Resource, CustomRoutes, defaultTheme, usePermissions } from "react-admin";
import { Route } from "react-router-dom";
import { ArtistCreate, ArtistEdit, ArtistList, ArtistShow } from "./artists";
import { dataProvider } from "./dataProvider";
import { AlbumCreate, AlbumEdit, AlbumList, AlbumShow } from "./albums";
import { TrackCreate, TrackEdit, TrackList, TrackShow } from "./tracks";
import { authProvider } from "./auth";
import Dashboard from "./dashboard";
import { GenreCreate, GenreEdit, GenreList, GenreShow } from "./genres";
import { CustomLogin } from "./login";
import { ArtistPayments } from "./artistsPayment";
import { initSentry } from "./sentry";
import { CheckForApplicationUpdate, Layout, LayoutProps } from "react-admin";
import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import {
    DemoTrackCreate,
    DemoTrackEditAdmin,
    DemoTrackList,
    DemoTrackListAdmin,
    DemoTrackShow,
    DemoTrackShowAdmin,
} from "./demoTracks";
import { ArtistAccessCreate, ArtistAccessEdit, ArtistAccessList, ArtistAccessShow } from "./artistAccess";
import { LabelList, LabelEdit, LabelShow, LabelCreate } from "./labels";
import { DDEXMessageCreate, DDEXMessageList, DDEXMessageShow } from "./ddexMessages";
import { DDEXDryRunList, DDEXDryRunShow } from "./ddexDryRuns";
import { UserFeedbackList, UserFeedbackShow } from "./userFeedbacks";
import { TagCreate, TagEdit, TagList, TagShow } from "./tags";
import {
    DDEXGenreMappingCreate,
    DDEXGenreMappingEdit,
    DDEXGenreMappingList,
    DDEXGenreMappingShow,
} from "./ddexGenreMappings";
import { CuratedTracks, FeaturedTracks } from "./featuredTracks";
import { AppMenu } from "./menu";
import {
    DDEXArtistMappingCreate,
    DDEXArtistMappingEdit,
    DDEXArtistMappingList,
    DDEXArtistMappingShow,
} from "./ddexArtistMappings";
import { ConfigEdit } from "./config";
import { ExportList, ExportShow } from "./exports";
import { DistributorCreate, DistributorEdit, DistributorList, DistributorShow } from "./distributors";

initSentry();

const VerifyAppUpdateLayout = ({ children, ...props }: LayoutProps) => (
    <Layout {...props} menu={AppMenu}>
        {children}
        <CheckForApplicationUpdate />
    </Layout>
);

let theme = defaultTheme;
if (import.meta.env.MODE !== "production") {
    theme = {
        ...theme,
        palette: {
            primary: indigo,
            secondary: pink,
            error: red,
        },
    };
}

const ArtistResources = () => [
    <Resource
        name="demo_tracks"
        list={DemoTrackList}
        show={DemoTrackShow}
        create={DemoTrackCreate}
        recordRepresentation="name"
    />,
];

const AdminResources = () => [
    <Resource
        name="artists"
        list={ArtistList}
        show={ArtistShow}
        edit={ArtistEdit}
        create={ArtistCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="albums"
        list={AlbumList}
        show={AlbumShow}
        edit={AlbumEdit}
        create={AlbumCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="tracks"
        list={TrackList}
        show={TrackShow}
        edit={TrackEdit}
        create={TrackCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="genres"
        list={GenreList}
        show={GenreShow}
        edit={GenreEdit}
        create={GenreCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="labels"
        list={LabelList}
        show={LabelShow}
        edit={LabelEdit}
        create={LabelCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="artist_access"
        list={ArtistAccessList}
        show={ArtistAccessShow}
        edit={ArtistAccessEdit}
        create={ArtistAccessCreate}
        recordRepresentation="name"
        options={{ label: "Artist access" }}
    />,
    <Resource
        name="demo_tracks"
        list={DemoTrackListAdmin}
        show={DemoTrackShowAdmin}
        edit={DemoTrackEditAdmin}
        create={DemoTrackCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="ddex/messages"
        list={DDEXMessageList}
        show={DDEXMessageShow}
        create={DDEXMessageCreate}
        recordRepresentation="id"
        options={{ label: "DDEX Messages" }}
    />,
    <Resource
        name="ddex/dry_runs"
        list={DDEXDryRunList}
        show={DDEXDryRunShow}
        recordRepresentation="id"
        options={{ label: "DDEX Dry Runs" }}
    />,
    <Resource
        name="ddex/genre_mappings"
        list={DDEXGenreMappingList}
        show={DDEXGenreMappingShow}
        create={DDEXGenreMappingCreate}
        edit={DDEXGenreMappingEdit}
        recordRepresentation="ddex_name"
        options={{ label: "DDEX Genre Mapping" }}
    />,
    <Resource
        name="ddex/artist_mappings"
        list={DDEXArtistMappingList}
        show={DDEXArtistMappingShow}
        create={DDEXArtistMappingCreate}
        edit={DDEXArtistMappingEdit}
        recordRepresentation="name"
        options={{ label: "DDEX Artist Mapping" }}
    />,
    <Resource name="user_feedbacks" list={UserFeedbackList} show={UserFeedbackShow} recordRepresentation="id" />,
    <Resource
        name="tags"
        list={TagList}
        show={TagShow}
        edit={TagEdit}
        create={TagCreate}
        recordRepresentation="name"
    />,
    <Resource name="exports" list={ExportList} show={ExportShow} recordRepresentation="name" />,
    <Resource
        name="distributors"
        list={DistributorList}
        show={DistributorShow}
        create={DistributorCreate}
        edit={DistributorEdit}
        recordRepresentation="name"
    />,
    <CustomRoutes>
        <Route path="/payments" element={<ArtistPayments />} />
        <Route path="/featured_tracks" element={<FeaturedTracks />} />
        <Route path="/curated_tracks" element={<CuratedTracks />} />
        <Route path="/config" element={<ConfigEdit />} />
    </CustomRoutes>,
];

export const App = () => (
    <Admin
        theme={theme}
        layout={VerifyAppUpdateLayout}
        loginPage={CustomLogin}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        requireAuth
    >
        {(permissions) => [...(permissions.mode === "admin" ? AdminResources() : ArtistResources())]}
    </Admin>
);
