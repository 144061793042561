import { CardContent, TextField } from "@mui/material";
import { Title, useNotify, useRedirect } from "react-admin";
import { DateRangePicker, Stack } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import { useState } from "react";
import { DownloadButton } from "./analytics";
import { DateTime } from "luxon";
import { httpClient, plainHttpClient } from "./dataProvider";
import { Button } from "@mui/material";

export const StartExportButton = (props: { url?: string; data: any }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [processing, setProcessing] = useState(false);

    const disabled = props.url === undefined;

    const onDownload = () => {
        if (props.url === undefined) {
            return;
        }

        setProcessing(true);

        const body = JSON.stringify(props.data);
        console.log(body);

        httpClient(props.url, { method: "POST", body: body })
            .then((response) => {
                redirect(`/exports/${response.json.export_id}/show`);
                setProcessing(false);
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setProcessing(false);
            });
    };

    return (
        <Button disabled={disabled || processing} onClick={onDownload} variant="contained" color="primary">
            Download CSV
        </Button>
    );
};

export const ArtistPayments = () => {
    const [range, setRange] = useState<DateRange | null>(null);
    const [adRevenue, setAdRevenue] = useState<string | null>(null);
    let artistDownloadUrl = import.meta.env.VITE_SERVER_URL + "/analytics/distributor_reporting";

    let reportingBody = null;

    if (range !== null) {
        let startDate = DateTime.fromJSDate(range[0]).toFormat("yyyy-MM-dd");
        let endDate = DateTime.fromJSDate(range[1]).toFormat("yyyy-MM-dd");

        reportingBody = { start_date: startDate, end_date: endDate, ad_revenue_usd: adRevenue };
    }

    let trackStatUrl = import.meta.env.VITE_SERVER_URL + "/analytics/track_stats";
    let trackStatFilename = `track_stats_${DateTime.now().toISODate()}.csv`;

    const handleDateRangeChange = (value: DateRange | null, event: any) => {
        setRange(value);
    };

    return (
        <div>
            <Title title="Analytics" />
            <CardContent>
                <h1>Artist payment</h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginBottom: "20px",
                        alignItems: "start",
                        marginTop: "20px",
                    }}
                >
                    <DateRangePicker
                        onChange={handleDateRangeChange}
                        format="yyyy-MM-dd"
                        placeholder="Select Date Range"
                    />
                    <TextField
                        label="Ad Revenue (USD)"
                        variant="outlined"
                        value={adRevenue}
                        onChange={(e) => setAdRevenue(e.target.value)}
                    />

                    <StartExportButton url={artistDownloadUrl} data={reportingBody} />
                </div>
                <h1>Track stats</h1>
                <DownloadButton url={trackStatUrl} filename={trackStatFilename} />
            </CardContent>
        </div>
    );
};
