import { AutocompleteInput, BulkDeleteButton, BulkExportButton, BulkUpdateButton, ReferenceInput } from "react-admin";
import { Visibility, VisibilityOff, Audiotrack, Home } from "@mui/icons-material";
import { BulkUpdateForm } from "./bulkUpdate";
import { NullableReferenceInput } from "./customFields";
import { LabelReferenceInput } from "./labels";

export const BulkActionButtons = () => (
    <>
        <BulkExportButton />
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

export const VisibilityBulkActionButtons = () => (
    <>
        <BulkUpdateButton
            label="Make public"
            data={{ is_public: true }}
            icon={<Visibility />}
            mutationMode="pessimistic"
        />
        <BulkUpdateButton
            label="Make private"
            data={{ is_public: false }}
            icon={<VisibilityOff />}
            mutationMode="pessimistic"
        />
        <BulkExportButton />
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

export const TrackBulkActionButtons = () => (
    <>
        <BulkUpdateButton
            label="Make public"
            data={{ is_public: true }}
            icon={<Visibility />}
            mutationMode="pessimistic"
        />
        <BulkUpdateButton
            label="Make private"
            data={{ is_public: false }}
            icon={<VisibilityOff />}
            mutationMode="pessimistic"
        />
        <BulkUpdateForm label="Update label" icon={<Audiotrack />}>
            <LabelReferenceInput />
        </BulkUpdateForm>
        <BulkUpdateForm label="Update distributor" icon={<Home />}>
            <ReferenceInput source="distributor_id" reference="distributors">
                <AutocompleteInput fullWidth />
            </ReferenceInput>
        </BulkUpdateForm>
        <BulkExportButton />
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);
