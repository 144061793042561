import * as React from "react";
import { Chip, Stack, StackProps, styled } from "@mui/material";
import PropTypes from "prop-types";
import {
    sanitizeListRestProps,
    useListContext,
    useResourceContext,
    RaRecord,
    RecordContextProvider,
    RecordRepresentation,
    ComponentPropType,
    useCreatePath,
} from "ra-core";
import { useRecordContext } from "react-admin";
import { LinearProgress, Link } from "react-admin";

/**
 * Iterator component to be used to display a list of entities, using a single field
 *
 * @example Display all the orders by the current customer as a list of chips
 * <ReferenceManyField reference="orders" target="customer_id">
 *     <SingleFieldList />
 * </ReferenceManyField>

* @example Choose the field to be used as text label
 * <ReferenceManyField reference="orders" target="customer_id">
 *     <SingleFieldList>
 *         <ChipField source="reference" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * @example Customize the link type
 * // By default, it includes a link to the <Edit> page of the related record
 * // (`/orders/:id` in the previous example).
 * // Set the linkType prop to "show" to link to the <Show> page instead.
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList linkType="show">
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * @example Disable the link
 * // You can also prevent `<SingleFieldList>` from adding link to children by
 * // setting `linkType` to false.
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList linkType={false}>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 */
export const OrderedSingleFieldList = (props: SingleFieldListProps) => {
    const { className, children, empty, linkType = "show", gap = 1, direction = "row", ...rest } = props;
    const { data, total, isLoading } = useListContext(props);
    const resource = useResourceContext(props);
    const createPath = useCreatePath();

    const record = useRecordContext();
    const idsArray = record[props.source];

    if (isLoading === true) {
        return <LinearProgress />;
    }

    if (data == null || data.length === 0 || total === 0) {
        if (empty) {
            return empty;
        }

        return null;
    }

    const dataMap = new Map(data.map((item) => [item.id, item]));
    // Build the sorted array by mapping over idArray
    const orderedData = idsArray.map((id: string) => dataMap.get(id));

    return (
        <Root gap={gap} direction={direction} className={className} {...sanitizeListRestProps(rest)}>
            {orderedData.map((record: any, rowIndex: any) => {
                if (!record) {
                    return <p>Not found</p>;
                }

                const resourceLinkPath = !linkType
                    ? false
                    : createPath({
                          resource,
                          type: linkType,
                          id: record.id,
                      });

                if (resourceLinkPath) {
                    return (
                        <RecordContextProvider value={record} key={record.id ?? `row${rowIndex}`}>
                            <Link
                                className={OrderedSingleFieldListClasses.link}
                                to={resourceLinkPath}
                                onClick={stopPropagation}
                            >
                                {children || <DefaultChildComponent clickable />}
                            </Link>
                        </RecordContextProvider>
                    );
                }

                return (
                    <RecordContextProvider value={record} key={record.id ?? `row${rowIndex}`}>
                        {children || <DefaultChildComponent />}
                    </RecordContextProvider>
                );
            })}
        </Root>
    );
};

OrderedSingleFieldList.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    component: ComponentPropType,
    data: PropTypes.any,
    empty: PropTypes.element,
    // @ts-ignore
    linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    resource: PropTypes.string,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
};

export interface SingleFieldListProps<RecordType extends RaRecord = any> extends StackProps {
    className?: string;
    empty?: React.ReactElement;
    linkType?: string | false;
    children?: React.ReactNode;
    // can be injected when using the component without context
    data?: RecordType[];
    total?: number;
    loaded?: boolean;
    source: string;
}

const PREFIX = "RaSingleFieldList";

export const OrderedSingleFieldListClasses = {
    link: `${PREFIX}-link`,
};

const Root = styled(Stack, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    flexWrap: "wrap",
    [`& .${OrderedSingleFieldListClasses.link}`]: {
        textDecoration: "none",
        "& > *": {
            color: theme.palette.primary.main,
        },
    },
}));

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

const DefaultChildComponent = ({ clickable }: { clickable?: boolean }) => (
    <Chip sx={{ cursor: "inherit" }} size="small" label={<RecordRepresentation />} clickable={clickable} />
);
