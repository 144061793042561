import {
    Datagrid,
    List,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    SelectField,
    useRecordContext,
} from "react-admin";
import { LongDateField } from "./customFields";
import { Button } from "@mui/material";

const ExportStatuses = [
    { id: "pending", name: "✉️ Pending" },
    { id: "completed", name: "✅ Completed" },
    { id: "error", name: "❌ Error" },
];

export const ExportList = () => (
    <List
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 1000]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
            <SelectField source="status" label="Status" choices={ExportStatuses} sortable={false} />
            <TextField source="user_email" label="User" />
            <LongDateField source="created_at" />
        </Datagrid>
    </List>
);

const DownloadExportButton = () => {
    const record = useRecordContext();

    if (!record) return null;
    const onDownload = () => {
        const link = document.createElement("a");
        link.href = record.download_url;
        link.setAttribute("download", record.filename ?? `export.zip`);

        link.click();
    };

    return (
        record.download_url && (
            <Button onClick={onDownload} variant="contained" color="primary">
                Download
            </Button>
        )
    );
};

export const ExportShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Export</h2>

            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
            <SelectField source="status" label="Status" choices={ExportStatuses} />
            <LongDateField source="created_at" label="Created at" />
            <DownloadExportButton />
            <TextField source="logs" component="pre" fontFamily={"monospace"} />
        </SimpleShowLayout>
    </Show>
);
