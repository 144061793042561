import { useEffect, useState } from "react";
import { useNotify, useShowController } from "react-admin";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { httpClient, plainHttpClient } from "./dataProvider";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";

const Graph = ({ data, name }: any) => {
    if (data === undefined) {
        return <p>Loading..</p>;
    }

    return (
        <ResponsiveContainer width="95%" height={400}>
            <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" name={name} stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export const DownloadButton = (props: { url?: string; filename: string; disabled?: boolean }) => {
    const notify = useNotify();
    const [processing, setProcessing] = useState(false);

    let disabled = props.disabled;
    if (disabled === undefined) {
        disabled = props.url === undefined;
    }

    const onDownload = () => {
        if (props.url === undefined) {
            return;
        }

        setProcessing(true);

        plainHttpClient(props.url, { method: "GET" })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", props.filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode?.removeChild(link);
                setProcessing(false);
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setProcessing(false);
            });
    };

    return (
        <Button disabled={disabled || processing} onClick={onDownload} variant="contained" color="primary">
            Download CSV
        </Button>
    );
};

export const Analytics = ({ resource }: { resource: string }) => {
    const { record } = useShowController();
    const [type, setType] = useState("listeners");
    const [data, setData] = useState<any>(undefined);
    const [duration, setDuration] = useState<number>(30);
    const notify = useNotify();
    const downloadUrl =
        import.meta.env.VITE_SERVER_URL + `/analytics/${resource}/${record.id}/csv?duration=${duration}`;
    const downloadFilename = `${record.name}.csv`;

    useEffect(() => {
        httpClient(import.meta.env.VITE_SERVER_URL + `/analytics/${resource}/${record.id}/${type}?duration=${duration}`)
            .then(({ json }) => {
                setData(json);
            })
            .catch((error) => {
                notify(error.message ?? error, { type: "error" });
            });
    }, [record.id, type, duration]);

    const handleChangeType = (event: React.MouseEvent<HTMLElement>, newType: string) => {
        if (newType !== null) {
            setType(newType);
        }
    };

    const handleChangeDuration = (event: SelectChangeEvent) => {
        setDuration(parseInt(event.target.value));
    };

    return (
        <div>
            <h3>Analytics</h3>
            <DownloadButton url={downloadUrl} filename={downloadFilename} />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    alignContent: "space-between",
                    gap: "10px",
                    marginBottom: "20px",
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "30px" }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={type}
                        exclusive
                        onChange={handleChangeType}
                        aria-label="Platform"
                    >
                        <ToggleButton value="listeners">Listeners</ToggleButton>
                        <ToggleButton value="streams">Streams</ToggleButton>
                        <ToggleButton value="saves">Saves to library</ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl>
                        <InputLabel>Duration</InputLabel>
                        <Select value={duration} label="Duration" onChange={handleChangeDuration}>
                            <MenuItem value={7}>7 days</MenuItem>
                            <MenuItem value={30}>30 days</MenuItem>
                            <MenuItem value={90}>3 month</MenuItem>
                            <MenuItem value={365}>1 year</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <Graph data={data?.values} name={type} />
            </div>
        </div>
    );
};
