import "rsuite/DateRangePicker/styles/index.css";
import { SettingsUpdate } from "./settingUpdate";
import { JSONInput } from "./customFields";

export const DEFAULT_IS_PUBLIC = false;

export const ConfigEdit = () => {
    return (
        <SettingsUpdate url="/cms/config" title="Config">
            <JSONInput label="Concerts" source="concerts" />
        </SettingsUpdate>
    );
};
